<template>
  <div style="background: #f7f7f7">
    <pcHeaders></pcHeaders>
    <div style="display: flex; justify-content: center">
      <div class="w">
        <div class="content"
             v-show="isShow">
          订单类型<el-input v-model="queryExl.ordType"
                    style="width: 200px; margin-top: 15px"></el-input> 开始时间<el-input v-model="queryExl.startTime"
                    style="width: 200px; margin-top: 15px"></el-input>
          结束时间<el-input v-model="queryExl.endTime"
                    style="width: 200px; margin-top: 15px"></el-input> 分类编号<el-input v-model="queryExl.codeNo"
                    style="width: 200px; margin-top: 15px"></el-input>
          <el-button type="warning"
                     plain
                     icon="el-icon-download"
                     size="mini"
                     @click="handleExport">导出</el-button>
        </div>
      </div>
      <el-dialog title="提示"
                 :visible.sync="dialogVisible"
                 width="30%"
                >
                请输入暗语：<el-input v-model="ay"
                style="width: 200px; margin-top: 15px"></el-input>
        <span slot="footer"
              class="dialog-footer">
          <el-button type="primary"
          @click="gg">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <pcBottom></pcBottom>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../../component/head.vue';
import pcBottom from '../../component/bottom.vue';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom
  },
  data() {
    // 这里存放数据
    return {
      queryExl: {},
      isShow: false,
      dialogVisible:true,
      ay:'',
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    gg() {
      if (this.ay == '冠成天下无敌') {
        this.dialogVisible = false
        this.$message({
          message: '恭喜你，暗语校验成功！',
          type:'success',
          
        })
        this.isShow = true
      }else {
        this.$message('暗语错误！')
      }
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        '/web/exportEnrollData.html',
        {
          ...this.queryExl
        },
        `log_${new Date().getTime()}.xlsx`
      );
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.center {
  display: flex;
  justify-content: center;
}
.w {
  width: 1200px;
  background: #f7f7f7;
  padding: 20px 0;
  font-family: Regular;
  height: 800px;
  .content {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
</style>
